import { Box, Button, Typography } from '@material-ui/core'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { getPlayerLadders } from '../api/server'
import LadderCardList from '../components/LadderCardList'
import Meta from '../components/Meta'
import Layout from '../components/Layout'
import { PlayerLadder } from '../types'
import { getFullUrl } from '../utils'
import { GetStaticProps } from 'next'

interface Props {
  recentLadders: PlayerLadder[]
}

const NotFoundPage = ({ recentLadders }: Props) => {
  const router = useRouter()

  return (
    <Layout showAds>
      <Meta url={getFullUrl(router.asPath)} title="Page not found" />

      <Box my={8}>
        <Typography variant="h1" align="center">
          Page not found
        </Typography>
      </Box>

      {recentLadders?.length > 0 && (
        <Box>
          <Box textAlign="center" my={2}>
            <Link href="/create" passHref>
              <Button variant="contained" color="primary">
                Create your own ladder
              </Button>
            </Link>
          </Box>

          <LadderCardList ladders={recentLadders} />

          <Box textAlign="center" my={2}>
            <Link href="/create" passHref>
              <Button variant="contained" color="primary">
                Create your own ladder
              </Button>
            </Link>
          </Box>
        </Box>
      )}
    </Layout>
  )
}

export default NotFoundPage

const ONE_HOUR = 60 * 60

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      recentLadders: await getPlayerLadders(12),
    },
    revalidate: ONE_HOUR,
  }
}
